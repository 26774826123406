import FullWrapper from 'core/FullWrapper';
import { sanitize } from 'isomorphic-dompurify';
import OfferForm from 'shared/sections/OfferBody/OfferForm';

import cn from './style.module.sass';

type OfferBodyProps = {
    defaultCheckedType: string;
    showCheckboxes: boolean;
    title: string;
    subtitle?: string;
    isEnterprise: boolean;
    isSolution?: boolean;
};
export default function OfferBody({
    title = 'Оставьте заявку',
    subtitle,
    showCheckboxes,
    defaultCheckedType,
    isEnterprise,
    isSolution,
}: OfferBodyProps) {
    return (
        <FullWrapper isMargin={false} caseName={cn.offerBody}>
            <div className={cn['offerBody__wrapper']} id="offer-body">
                <h2 className={cn['offerBody__title']}>{title}</h2>
                {subtitle && (
                    <div
                        dangerouslySetInnerHTML={{ __html: sanitize(subtitle) }}
                        className={cn['offerBody__subtitle']}
                    />
                )}
                {/* eslint-disable-next-line sonarjs/no-all-duplicated-branches */}
                {isSolution ? (
                    <OfferForm
                        showCheckboxes={showCheckboxes}
                        defaultCheckedType={defaultCheckedType}
                        isEnterprise={isEnterprise}
                        isSolution={true}
                    />
                ) : (
                    <OfferForm
                        showCheckboxes={showCheckboxes}
                        defaultCheckedType={defaultCheckedType}
                        isEnterprise={isEnterprise}
                    />
                )}
            </div>
        </FullWrapper>
    );
}
